<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <div style="display: flex">
        <h1
          style="width: calc(100% - 100px);"
          class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
        >
          Tix Sold in Date
        </h1>

        <!-- <select
          class="form-select"
          @change="rangeSelection"
          style="color: #4a5568;
        padding: 0.5rem 1.7rem;
        line-height: 1.25;
        border-width: 2px;
        border-radius: 0.25rem;
        border-color: #007fff;
        background-color: #edf2f7;
        margin-bottom: 4px;"
        >
          <option value="">Select Date Range</option>
          <option value="3">3 days</option>
          <option value="7">7 days</option>
          <option value="30">30 days</option>
        </select> -->
      </div>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table
            id="tickets"
            class="border-collapse stripe hover w-full text-sm"
            style="overflow: scroll;
            overflow-x: scroll;
            display: block;
            position: initial;"
            v-if="data.length"
          >
            <thead>
              <tr>
                <th data-priority="1">
                  Event
                </th>

                <th data-priority="2">
                  TM Link
                </th>
                <th data-priority="3">
                  Favourite
                </th>
                <th data-priority="4">
                  Venue
                </th>
                <th data-priority="5">
                  Tix Sold In Date
                </th>
                <th data-priority="6">
                  Avg Sale Price
                </th>
                <th data-priority="7" style="display:none;">
                  id
                </th>
                <th data-priority="8" style="display:none;">
                  venueid
                </th>
                <th data-priority="9" style="display:none;">
                  City
                </th>
                <th data-priority="10" style="display:none;">
                  State
                </th>
                <th data-priority="11" style="display:none;">
                  Performer Id
                </th>
                <th data-priority="12" style="display:none;">
                  Category
                </th>
                <th data-priority="13" style="display:none;">
                  Avail Tixs
                </th>
                <th data-priority="14" style="display:none;">
                  Total Value
                </th>
                <th data-priority="15" style="display:none;">
                  Avg Value
                </th>
                <th data-priority="16" style="display:none;">
                  Last Event Date
                </th>
                <th data-priority="17" style="display:none;">
                  Dates
                </th>
                <th data-priority="18" style="display:none;">
                  Time
                </th>
                <th data-priority="18" style="display:none;">
                  isactive
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr v-for="tr in data" :key="tr.id" id="tix-data-wait">
                <td>{{ tr.event }}</td>
                <td>{{ tr.event }}</td>
                <td id="test7">{{ tr.favorite }}</td>
                <td>{{ tr.venuename }}</td>
                <td>
                  {{ tr.tixsoldindaterange }}
                </td>
                <td>
                  {{ tr.avgsaleprice }}
                </td>
                <td style="display:none">{{ tr.id }}</td>
                <td style="display:none">{{ tr.venueid }}</td>
                <td style="display:none;">{{ tr.city }}</td>
                <td style="display:none">{{ tr.state }}</td>
                <td style="display:none">{{ tr.performerid }}</td>
                <td style="display:none">{{ tr.category }}</td>
                <td style="display:none">{{ tr.availabletixs }}</td>
                <td style="display:none">{{ tr.totalvalue }}</td>
                <td style="display:none">{{ tr.avgvalue }}</td>
                <td style="display:none">{{ tr.lasteventdate }}</td>
                <td style="display:none">{{ tr.dates }}</td>
                <td style="display:none">{{ tr.createdat }}</td>
                <td style="display:none">{{ tr.isactive }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="!data.length" class="text-center">
            {{ loading }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import * as moment from "moment";

export default {
  name: "NewTickets",
  data() {
    return {
      loading: "Please wait...",
      data: [],
      addLoading: -1,
      daterange: ""
    };
  },
  created() {
    this.get();
  },
  methods: {
    addBookmark(item, i) {
      this.addLoading = i;
      this.$axios
        .post(`${this.$api}/bookmark/add`, {
          userId: localStorage.getItem("user-id"),
          event: item.event
        })
        .then(res => {
          alert(res.data.message);
          this.addLoading = -1;
        })
        .catch(err => {
          this.addLoading = -1;
          console.log(err);
          alert("Something went wrong, please try again");
        });
    },
    dateParse(dt) {
      return moment(moment(dt).utcOffset("-06:00")).format(
        "MMMM Do YYYY, hh:mm A"
      );
    },
    rangeSelection(e) {
      console.log(e.target.value);
      this.daterange = e.target.value;
      this.data = [];
      this.city = [];
      this.jQuery("#tickets")
        .DataTable()
        .destroy();
      this.loading = "Please wait...";
      this.get();
    },
    addCustomSelect() {
      const parentGuest = document.getElementById("tickets_filter");
      var array = ["", 3, 7, 30];
      //Create and append select list
      var selectList = document.createElement("select");
      selectList.style.float = "right";
      selectList.style.marginRight = "15px";
      selectList.id = "rangeSelection";
      selectList.setAttribute("v-on:change", "rangeSelection()");
      parentGuest.parentNode.insertBefore(selectList, parentGuest.nextSibling);
      //Create and append the options
      for (var i = 0; i < array.length; i++) {
        var option = document.createElement("option");
        option.value = array[i];
        option.text = array[i] == "" ? "Select Date Range" : array[i] + " days";
        selectList.appendChild(option);
      }
    },
    get() {
      const daterangeVal = this.daterange ? `?daterange=${this.daterange}` : "";
      this.$axios
        .get(`${this.$api}/ticket/get${daterangeVal}`)
        .then(res => {
          this.data = res.data.data;
          console.log(this.data[0].city);
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          var self = this;
          var existTix = setInterval(() => {
            if (self.jQuery("#tix-data-wait").length) {
              clearInterval(existTix);
              self.jQuery("#tickets").DataTable({
                retrieve: true,
                deferRender: true,
                responsive: true,
                aLengthMenu: [100],
                aaSorting: [[4, "desc"]],
                processing: true,
                language: {
                  processing:
                    "<i class='fa fa-refresh fa-spin' style='font-size:4em;margin-top:20%;'></i>"
                },
                serverSide: true,
                ajax: {
                  url: `${self.$api}/ticket/get`,
                  data: { daterange: self.daterange },
                  dataFilter: function(data) {
                    const records = JSON.parse(data);
                    const json = records.data;
                    return JSON.stringify({
                      recordsTotal: records.total,
                      recordsFiltered: records.total,
                      data: json
                    }); // return JSON string
                  }
                },
                // drawCallback: function (responseData) {
                //   return responseData.json.data;
                // },
                deferLoading: res.data.total,

                columns: [
                  {
                    data: "event",
                    title: "Event",
                    render: function(data, _type, row) {
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/ticket-chart/${data}/${row.city}"
                        target="_blank"
                        >${data}</a
                      >`;
                    }
                  },
                  {
                    data: "event",
                    title: "TM Link",
                    render: function(data, _type, row) {
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/test/${data}/${row.city}"
                        target="_blank"
                        ><img src="https://64.227.185.57/frontend/img/ticket.jpeg" alt="img" style="max-width: 30px;"></a
                      >`;
                    }
                  },
                  {
                    data: "favorite",
                    title: "Favourite",

                    render: function(data, _type, row) {
                      var data2 = row.event;

                      var name2 = data2.replace("/", "-");

                      var lasteventdate = row.lasteventdate;

                      lasteventdate = lasteventdate.replaceAll("/", "-");

                      // console.log(row);
                      // console.log("d1");
                      return data == 0
                        ? `<a href='/admin/test3/${row.id}/${row.venueid}/${row.venuename}/${row.city}/${row.state}/${name2}/${row.performerid}/${row.category}/${row.availabletixs}/${row.totalvalue}/${row.avgvalue}/${lasteventdate}/${row.tixsoldindaterange}/${row.avgsaleprice}/${row.createdat}/${row.isactive}'><i class='fa fa-heart btn-edit'" id='solTitle' style='font-size:2em;'></i></a>`
                        : `<i class='fa fa-heart'  onclick="editMode1()" style='font-size:2em; color:red;'></i>`;
                    }
                  },
                  { data: "venuename" },
                  { data: "tixsoldindaterange" },
                  { data: "avgsaleprice" },
                  {
                    data: "id",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "venueid",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "city",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "state",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "performerid",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "category",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "availabletixs",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "totalvalue",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "avgvalue",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "lasteventdate",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "dates",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "createdat",
                    render: function() {
                      return ``;
                    }
                  },
                  {
                    data: "isactive",
                    render: function() {
                      return ` `;
                    }
                  }
                ]
              });
              console.log(this.data);
            }
            // this.addCustomSelect();
          }, 100);
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    },
    editMode(tr) {
      // //alert(JSON.stringify(tr));
      // console.log("tr");
      // console.log(tr);
      this.username1;
      var user_id = localStorage.getItem("user-id");

      //alert(user_id);
      this.$axios
        .get(`${this.$api}/username/${user_id}`)
        .then(res => {
          //alert(res.data);
          //console.log(res.data.data[0].username);
          this.username1 = res.data.data[0].username;
          console.log(this.username1);
          //this.addLoading = -1;
          this.$axios
            .post(`https://varilytics.com/api/addfavourite_events`, {
              userid: this.username1,
              eventid: tr.id,
              venueid: tr.venueid,
              venuename: tr.venuename,
              city: tr.city,
              state: tr.state,
              event: tr.event,
              performerid: tr.performerid,
              category: tr.category,
              availabletixs: tr.availabletixs,
              totalvalue: tr.totalvalue,
              avgvalue: tr.avgvalue,
              lasteventdate: tr.lasteventdate,
              dates: tr.dates,
              tixsoldindaterange: tr.tixsoldindaterange,
              avgsaleprice: tr.avgsaleprice,
              createdate: tr.createdat,
              isactive: tr.isactive,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token"
              }
            })
            .then(result => {
              //var re = JSON.parse(result);
              alert(result.data.message);
              window.location.reload();
              //alert(re.message);
              //this.fa-heart.color = this.colors.green;
            })
            .catch(error => console.log("error", error));
        })
        .catch(err => {
          //this.addLoading = -1;
          console.log(err);
          //alert("Something went wrong, please try again");
        });
    }
  }
};
</script>

<style scoped>
/*Overrides for Tailwind CSS */

/*Form fields*/
.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
  color: #4a5568; /*text-gray-700*/
  padding-left: 1rem; /*pl-4*/
  padding-right: 1rem; /*pl-4*/
  padding-top: 0.5rem; /*pl-2*/
  padding-bottom: 0.5rem; /*pl-2*/
  line-height: 1.25; /*leading-tight*/
  border-width: 2px; /*border-2*/
  border-radius: 0.25rem;
  border-color: #edf2f7; /*border-gray-200*/
  background-color: #edf2f7; /*bg-gray-200*/
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 3px !important;
}
table.dataTable {
  width: 100% !important;
  /*margin-left: -90px;*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #ebf4ff; /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Current selected */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Hover */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Add padding to bottom border */
table.dataTable.no-footer {
  border-bottom: 1px solid #e2e8f0; /*border-b-1 border-gray-300*/
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #667eea !important; /*bg-indigo-500*/
}
</style>
